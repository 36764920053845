if (typeof FN == 'undefined') FN = {};

FN.config = {
  urls: {
    base: false,
    apiBase: false,
    licensingBase: false,
    checkoutApi: false,
    apiContactSubscribe: 'contacts/subscribe',
    apiContactUpdate: 'contacts/update',
    apiEnterpriseContact: 'deal/add',
    apiJobApplication: 'emails/job-application',
    apiTicketSupport: 'tickets',
    apiEnterpriseLead: 'sales_leads/enterprise',
    apiTeamLead: 'sales_leads/team',
    ticketSupportSuccess: 'support/contact/message-sent',
    helpSearchMac: 'https://www.git-tower.com/help/search/mac',
    helpSearchWindows: 'https://www.git-tower.com/help/search/windows'
  },
  analytics: {
    siteID: 'tower-website',
    addPlatformToEventLabel: true
  },
  responsiveBreakpoints: [
    // NOTE: values here must be the same as in _variables.scss!!
    { 'media': 'xl', 'width': '1400px' },
    { 'media': 'l', 'width': '1100px' },
    { 'media': 'm', 'width': '760px' },
    { 'media': 's', 'width': '420px' },
    { 'media': 'xs', 'width': '1px' }
  ]
};

FN.config.setEnvironmentUrls = function (type) {
  var urls = { 'base': false, 'apiBase': false };
  var host = window.location.host;

  if (host.indexOf('website-tower') === 0) {
    // development
    urls.base = '/';
    urls.apiBase = 'http://website-api.test/';
    urls.licensingBase = 'https://staging.account.git-tower.com/api/';
    urls.checkoutApi = 'http://website-tower.test/store/api/';
    urls.educationApi = 'http://website-tower.test/a/education-api/';
  } else if (host.indexOf('staging.git-tower.com') === 0) {
    // staging
    urls.base = '/';
    urls.apiBase = 'https://staging.git-tower.com/api/';
    urls.licensingBase = 'https://staging.account.git-tower.com/api/';
    urls.checkoutApi = 'https://staging.git-tower.com/store/api/';
    urls.educationApi = 'https://staging.git-tower.com/a/education-api/';
  } else {
    // production
    urls.base = '/';
    urls.apiBase = 'https://www.git-tower.com/api/';
    urls.licensingBase = 'https://account.git-tower.com/api/';
    urls.checkoutApi = 'https://www.git-tower.com/store/api/';
    urls.educationApi = 'https://www.git-tower.com/a/education-api/';
  }

  FN.config.urls.base = urls.base;
  FN.config.urls.apiBase = urls.apiBase;
  FN.config.urls.licensingBase = urls.licensingBase;
  FN.config.urls.checkoutApi = urls.checkoutApi;
  FN.config.urls.educationApi = urls.educationApi;
}
FN.config.setEnvironmentUrls();




/*********** Configure Analytics Event Tracking */
FN.config.analyticsEvents = [
  /* Newsletter Subscription */
  {
    'config': { 'selector': '.overlay__container--download .newsletter-form.analytics--submit', 'triggerOnlyOnce': true },
    'event': { 'eventCategory': 'newsletter', 'eventAction': 'signup', 'eventLabelSuffix': 'trial-download' }
  },
  {
    'config': { 'selector': '#after-download-modal .newsletter-form.analytics--submit', 'triggerOnlyOnce': true },
    'event': { 'eventCategory': 'newsletter', 'eventAction': 'signup', 'eventLabelSuffix': 'trial-download' }
  },
  {
    'config': { 'selector': '.overlay__container--trial-via-email .newsletter-form.analytics--submit', 'triggerOnlyOnce': true },
    'event': { 'eventCategory': 'newsletter', 'eventAction': 'signup', 'eventLabelSuffix': 'trial-via-email' }
  },
  {
    'config': { 'selector': '#trial-via-email .newsletter-form.analytics--submit', 'triggerOnlyOnce': true },
    'event': { 'eventCategory': 'newsletter', 'eventAction': 'signup', 'eventLabelSuffix': 'trial-via-email' }
  },
  /* Trial Download Buttons */
  {
    'config': { 'selector': '.nav-top--standard .visible-on--mac .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-mac', 'eventLabelSuffix': 'top-navi' }
  },
  {
    'config': { 'selector': '.nav-top-download .visible-on--mac .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-mac', 'eventLabelSuffix': 'top-navi' }
  },
  {
    'config': { 'selector': '.nav-top--standard .visible-on--windows .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-windows', 'eventLabelSuffix': 'top-navi' }
  },
  {
    'config': { 'selector': '.nav-top-download .visible-on--windows .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-windows', 'eventLabelSuffix': 'top-navi' }
  },
  {
    'config': { 'selector': 'header .visible-on--mac .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-mac', 'eventLabelSuffix': 'header' }
  },
  {
    'config': { 'selector': 'header .visible-on--windows .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-windows', 'eventLabelSuffix': 'header' }
  },
  {
    'config': { 'selector': '#content_button-bar--middle .visible-on--mac .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-mac', 'eventLabelSuffix': 'mid-page' }
  },
  {
    'config': { 'selector': '#content_button-bar--middle .visible-on--windows .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-windows', 'eventLabelSuffix': 'mid-page' }
  },
  {
    'config': { 'selector': '#content_button-bar--footer .visible-on--mac .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-mac', 'eventLabelSuffix': 'footer' }
  },
  {
    'config': { 'selector': '#content_button-bar--footer .visible-on--windows .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-download-windows', 'eventLabelSuffix': 'footer' }
  },
  /* Trial via Email Buttons */
  {
    'config': { 'selector': '.nav-top--mobile .button--start-trial.analytics--click' },
    'event': { 'eventCategory': 'sales', 'eventAction': 'trial-via-email', 'eventLabelSuffix': 'top-navi' }
  },
  /* Twitter */
  {
    'config': { 'selector': '.overlay__container--download .button--twitter.analytics--click' },
    'event': { 'eventCategory': 'social', 'eventAction': 'share', 'eventLabelSuffix': 'twitter' }
  },
  {
    'config': { 'selector': '#after-subscribe-modal .button--twitter.analytics--click' },
    'event': { 'eventCategory': 'social', 'eventAction': 'share', 'eventLabelSuffix': 'twitter' }
  },
  /* Page Interactions */
  {
    'config': { 'selector': '.announcement-bar--introductory-pricing .button.analytics--click', 'waitBeforeDefault': true },
    'event': { 'eventCategory': 'sales', 'eventAction': 'discount-button', 'eventLabelSuffix': 'introductory-pricing' }
  },
  {
    'config': { 'selector': '.referrer-content--none .button.analytics--click', 'waitBeforeDefault': true },
    'event': { 'eventCategory': 'page-interaction', 'eventAction': 'button', 'eventLabelSuffix': 'show-new-features' }
  },
  /* Enterprise/team contact forms */
  {
    'config': { 'selector': '#enterprise-contact__form.analytics--submit', 'triggerOnlyOnce': true },
    'event': { 'eventCategory': 'sales', 'eventAction': 'enterprise-request', 'eventLabelSuffix': 'contact-form' }
  },
  {
    'config': { 'selector': '#team-contact__form.analytics--submit', 'triggerOnlyOnce': true },
    'event': { 'eventCategory': 'sales', 'eventAction': 'enterprise-request', 'eventLabelSuffix': 'contact-form' }
  }
];



// Quick'n'dirty Fathom events for downloads (Mac, Windows and "all") as well as
// for reaching the "order completed" page
// TODO: Should probably be extracted into a separate place
window.addEventListener('DOMContentLoaded', () => {
  const macDownloadSelectors = [
    '.nav-top--standard .visible-on--mac .button--start-trial.analytics--click',
    'header .visible-on--mac .button--start-trial.analytics--click',
    '#content_button-bar--middle .visible-on--mac .button--start-trial.analytics--click',
    '#content_button-bar--footer .visible-on--mac .button--start-trial.analytics--click'
  ];

  function handleMacDownload() {
    if (window.fathom) {
      // "Download Trial — all"
      window.fathom.trackGoal('CEZJGYMP', 0);
      // "Download Trial — Mac"
      window.fathom.trackGoal('OOICDQYE', 0);
    }
  }

  macDownloadSelectors.forEach((selector) => {
    const macDownloadButtons = document.querySelectorAll(selector);
    if (macDownloadButtons.length > 0) {
      macDownloadButtons.forEach((button) => {
        button.addEventListener('click', handleMacDownload);
      });
    }
  });

  const windowsDownloadSelectors = [
    '.nav-top--standard .visible-on--windows .button--start-trial.analytics--click',
    'header .visible-on--windows .button--start-trial.analytics--click',
    '#content_button-bar--middle .visible-on--windows .button--start-trial.analytics--click',
    '#content_button-bar--footer .visible-on--windows .button--start-trial.analytics--click'
  ];

  function handleWindowsDownload() {
    if (window.fathom) {
      // "Download Trial — all"
      window.fathom.trackGoal('CEZJGYMP', 0);
      // "Download Trial — Windows"
      window.fathom.trackGoal('GRHVWPYL', 0);
    }
  }

  windowsDownloadSelectors.forEach((selector) => {
    const windowsDownloadButtons = document.querySelectorAll(selector);
    if (windowsDownloadButtons.length > 0) {
      windowsDownloadButtons.forEach((button) => {
        button.addEventListener('click', handleWindowsDownload);
      });
    }
  });

  const orderCompletedSelector = "#page--store__order-completed";

  function handleOrderCompleted() {
    if (window.fathom) {
      // "Purchase"
      window.fathom.trackGoal('3IQFXA38', 0);
    }
  }

  // Are we on the "order completed" page?
  if (document.querySelector(orderCompletedSelector)) {
    handleOrderCompleted();
  }
});



$(document).ready(function () {
  /*********** Toggle Mac / Windows Specific Content */
  FN.osSwitch.init();
  $(document).on("FN.osSwitch:switched", function (event, context) {
    FN.contentTextWithMedia.static.initAllItems();
    FN.contentTextWithContent.static.initAllItems();
  });

  /*********** Rewrite URL to include OS string ('mac' / 'windows') */
  if (typeof FN.preloadConfig !== 'undefined' && FN.preloadConfig.forceOSinURL === false) {
    // don't force OS in URL
  } else {
    // force OS in URL
    FN.osSwitch.forceOSinURL = true;
    FN.osSwitch.writeOSinURL();
  }

  /*********** init analytics */
  FN.analytics.addConfiguredEvents(FN.config.analyticsEvents);

  /*********** prefill "source[OS]" value in newsletter forms */
  if ($('input[name="signup[OS]"]').length > 0) {
    $('input[name="signup[OS]"]').val(FN.helpers.platform());
  }

  /*********** Init "text-with-media" items */
  if (typeof FN.contentTextWithMedia !== 'undefined') FN.contentTextWithMedia.static.initAllItems();

  /*********** Init "text-with-content" items */
  if (typeof FN.contentTextWithContent !== 'undefined') FN.contentTextWithContent.static.initAllItems();

  /*********** Start autoplay videos */
  if (typeof FN.videoAutoplayer !== 'undefined') FN.videoAutoplayer.init();

  /********** Screencast Video Player */
  if (typeof FN.videoPlayer !== 'undefined') FN.videoPlayer.init();

  /********** Start trial / app download overlay */
  if (typeof FN.startTrial !== 'undefined') FN.startTrial.init();

  /********** Newsletter signup */
  $('.' + FN.newsletterConfig.selectors.formClass).each(function (index, element) {
    FN.newsletterConfig.instances.push(new FN.newsletter(element));
  });

});
