/******************
* 
* Allows to mark content that should be displayed only on specific environments.
*
* HTML content can be marked using the following CSS classes:
*   - .visible-on--mobile
*   - .visible-on--desktop
*   - .visible-on--mac
*   - .visible-on--windows
*
* This way, content can be written/included for multiple platforms; 
* on page load, any content that doesn't match the actual platform of the user will be hidden.
*/

if(typeof FN == 'undefined') FN = {};


FN.visibleOn = {
  defaultOS: 'mac',
  currentOS: false,
  otherOS: false,
  isMobile: false,
  mobileBreakpoint: '760px',
  selectors: '.visible-on--mobile, .visible-on--desktop, .visible-on--mac, .visible-on--windows',
  
  toggleContent: function(){
    // determine current OS and device width
    var currentOS = FN.visibleOn.defaultOS;
    if('osSwitch' in FN){
      FN.visibleOn.currentOS = FN.osSwitch.detectPlatform();
    }else{
      FN.visibleOn.currentOS = FN.helpers.platform();
    }
    FN.visibleOn.otherOS = (FN.visibleOn.currentOS == 'mac') ? 'windows' : 'mac';
    FN.visibleOn.isMobile = (window.matchMedia('(min-width: '+ FN.visibleOn.mobileBreakpoint +')').matches) ? false : true;

    // hiding criteria
    var hideOnOS = 'visible-on--' + FN.visibleOn.otherOS;
    var hideOnDevice = (FN.visibleOn.isMobile) ? 'visible-on--desktop' : 'visible-on--mobile';

    // iterate through all items with at least one of the corresponding CSS selectors
    $(FN.visibleOn.selectors).each(function(index, element){
      var elementClass = $(element).attr('class');
      // if at least one hiding criteria is met, the element must be hidden (independent of other criteria)
      if(elementClass.indexOf(hideOnOS) > -1 || elementClass.indexOf(hideOnDevice) > -1){
        $(element).hide();
      }else{
        $(element).show();
      }
    });
  }
}




$(document).ready(function(){  
  FN.visibleOn.toggleContent();
});
