document.addEventListener('DOMContentLoaded', () => {
  const nav = document.querySelector('.nav-top-common');
  const showLink = document.querySelector('.nav-top-show');

  const primaryLinks = Array.from(document.querySelectorAll('.nav-top-primary > li > a'))

  showLink.addEventListener('click', () => {
    nav.classList.toggle('active');
  });

  primaryLinks.forEach(link => {
    const secodaryList = link.nextElementSibling;
    const hasSecondaryList = secodaryList && secodaryList.classList.contains('nav-top-secondary');
    if (!hasSecondaryList) return;

    link.parentElement.addEventListener('mouseover', () => {
      link.dataset.overAt = Date.now();
    });

    link.addEventListener('click', event => {
      const isMobile = window.matchMedia('(max-width: 900px)').matches;
      if (isMobile) return;

      // Don't follow the link if it's tapped when the list is just opened. This
      // is required for touch interfaces which handle `mouseover` and `click`
      // together, therefore not allowing to view and select items on the
      // secondary list.
      if (link.dataset.overAt && Date.now() - link.dataset.overAt < 100)
        event.preventDefault();
    });
  });

  nav.towerLastScrollPosition = 0;
  nav.towerScrollOffset = 0;
  document.addEventListener('scroll', () => {
    const isOnMobile = window.matchMedia('(max-width: 900px)').matches;
    if (isOnMobile) return;

    const paralaxFactor = 0.8;
    const style = window.getComputedStyle(nav);
    const shadow = 20; // The size of the navigation pill's shadow
    const limit = (parseFloat(style.height) + parseFloat(style.marginTop) + shadow) / paralaxFactor;

    const diff = nav.towerLastScrollPosition - window.scrollY;
    if (diff < 0 && window.scrollY > 0) {
      const offset = Math.max(-limit, Math.min(0, nav.towerScrollOffset + diff));
      nav.towerScrollOffset = offset;
      nav.style.top = `${Math.round(offset * paralaxFactor)}px`;
      nav.style.transition = 'none';
    } else {
      nav.towerScrollOffset = 0;
      nav.style.top = `0px`;
      nav.style.transition = 'top 0.2s';
    }

    nav.towerLastScrollPosition = window.scrollY;
  });

  nav.addEventListener('mouseover', () => {
    nav.towerScrollOffset = 0;
    nav.style.top = '0px';
    nav.style.transition = 'top 0.2s';
  });
});
