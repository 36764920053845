if(typeof FN == 'undefined') FN = {};

FN.osSwitch = {
  defaultPlatform: 'mac',
  currentPlatform: false,
  otherPlatform: false,
  forceOSinURL: null,
  init: function(){
    var platform = FN.osSwitch.detectPlatform();
    FN.osSwitch._setPlatform(platform);

    // order os-switch buttons (current platform appears first)
    FN.osSwitch.orderButtons();

    // attach event handler to os-switch buttons
    $(document).on('click', '.os-switch', function(){
      FN.osSwitch.switchPlatform(this);
    });
  },

  toggleContent: function(){
    $('.visible-on--' + FN.osSwitch.otherPlatform).hide();
    $('.visible-on--' + FN.osSwitch.currentPlatform).show();
  },

  detectPlatform: function(forcePlatform){
    var platform = false;
    var platformFromURL = FN.osSwitch._getPlatformFromURL();
    var platformFromCookie = FN.osSwitch._getPersistedPlatformChoice();
    var platformFromBrowser = FN.helpers.platform();

    if(forcePlatform){
      // set the platform to a given value (without looking at the user's machine)
      platform = forcePlatform;
    }else if(platformFromURL){
      // if the URL starts with "mac" or "windows", set it to this value
      platform = platformFromURL;
    }else if(platformFromCookie){
      // if a Cookie from a previous visit is set, use this value
      platform = platformFromCookie;
    }else if(platformFromBrowser !== 'other'){
      // set it by looking at the user's browser / machine
      platform = platformFromBrowser;
    }else{
      // use a default
      platform = FN.osSwitch.defaultPlatform;
    }

    return platform;
  },

  switchPlatform: function(clickedSwitch){
    var chosenPlatform = $(clickedSwitch).hasClass('os-switch--mac') ? 'mac' : 'windows';
    FN.osSwitch._setPlatform(chosenPlatform);

    $(clickedSwitch).siblings().removeClass('selected').addClass('selectable');
    $(clickedSwitch).addClass('selected').removeClass('selectable');

    FN.osSwitch.toggleContent();
    
    // trigger a custom jQuery event named "FN.osSwitch:switched", which other code can listen to:
    // $(document).on("FN.osSwitch:switched", function(event, context){ ...
    $(document).trigger( "FN.osSwitch:switched", { 'newCurrentPlatform': chosenPlatform } );
  },

  writeOSinURL: function(){
    var pathname = document.location.pathname;
    var currentPlatformRule = FN.osSwitch.currentPlatform + "\/?$";
    var otherPlatformRule   = FN.osSwitch.otherPlatform + "\/?$";
    if(pathname.match(currentPlatformRule)) return;

    // check if we have to replace an "old" platform value in the URL
    if(pathname.match(otherPlatformRule)){
      var newURL = document.location.href.replace(FN.osSwitch.otherPlatform, FN.osSwitch.currentPlatform);
      history.replaceState(false, document.title, newURL);
    }else{
      var newURLSuffix = pathname.match("\/$") ? (FN.osSwitch.currentPlatform) : ('/' + FN.osSwitch.currentPlatform);
      var newURL = document.location.origin + document.location.pathname + newURLSuffix + document.location.search;
      history.replaceState(false, document.title, newURL);
    }
  },

  orderButtons: function(){
    // only order buttons when 'both' OS are being displayed
    $('.os-selection--both').each(function(index, element){
      var otherPlatformButton = $(element).find('.os-switch--' + FN.osSwitch.otherPlatform);
      var currentPlatformButton = $(element).find('.os-switch--' + FN.osSwitch.currentPlatform);
      var connectingWord = $(element).find('.os-switch__connector');
      var prefixText = $(element).find('.os-switch__prefix');

      $(currentPlatformButton).addClass('selected').removeClass('selectable');
      $(otherPlatformButton).addClass('selectable').removeClass('selected');

      $(element).html('').append(prefixText).append(currentPlatformButton).append(connectingWord).append(otherPlatformButton);
    });
  },

  _setPlatform: function(platform){
    FN.osSwitch.currentPlatform   = platform;
    FN.osSwitch.otherPlatform     = (platform === 'mac') ? 'windows' : 'mac';
    FN.osSwitch._persistPlatformChoice();

    if(FN.osSwitch.forceOSinURL === true) FN.osSwitch.writeOSinURL();
  },

  _getPlatformFromURL: function(){
    var pathname = document.location.pathname;
    if(pathname.match("mac\/?$")){
      return 'mac';
    }else if(pathname.match("windows\/?$")){
      return 'windows';
    }else{
      return false;
    }
  },

  _persistPlatformChoice: function(){
    Cookies.set('platform_choice', FN.osSwitch.currentPlatform);
  },

  _getPersistedPlatformChoice: function(){
    if(Cookies.get('platform_choice')){
      return Cookies.get('platform_choice');
    }else{
      return false;
    }
  }
};
