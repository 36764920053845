if (typeof FN == 'undefined') FN = {};


FN.startTrial = {
  constants: {
    downloadImage: 'assets/img/icons/tower-icon.svg'
  },
  selectors: {
    newsletterSignupId: 'signup-after-download-form',
    trialViaEmailOverlayBaseClass: 'overlay__container--trial-via-email',
    downloadOverlayBaseClass: 'overlay__container--download',
    downloadAnimationSelector: '.overlay__content--1 .header__icon'
  },


  init: function () {
    // $('.button--start-trial').on('click', function(event){
    //   event.preventDefault();
    //   FN.startTrial.startWorkflow(event);
    // });

    // $(document).on("FN.newsletter:success", function(event, context){
    //   var formElementId = context.formElement.attr('id');
    //   if(formElementId === FN.startTrial.selectors.newsletterSignupId){
    //     // check the form's ID to make sure this is "our" event
    //     FN.startTrial.signupComplete(context);
    //   }
    // });
  },


  startWorkflow: function (event) {
    if (FN.helpers.isMobileDevice()) {
      // show "trial via email" overlay
      FN.startTrial.showTrialViaEmailOverlay();

    } else {
      // start trial file download
      FN.startTrial.startTrialTracking();
      FN.startTrial.startFileDownload(event);
      FN.startTrial.showDownloadOverlay();
      FN.startTrial.showDownloadAnimation();
    }
  },


  showTrialViaEmailOverlay: function () {
    var baseElementSelector = '.' + FN.startTrial.selectors.trialViaEmailOverlayBaseClass;
    FN.overlay.init({ baseElement: baseElementSelector });
  },


  startTrialTracking: function () {
    // Get GA client ID from 'ga' object, get campaign query parameters
    //
    // If the 'ga' object isn't available, or extraction of the client ID
    // otherwise fails, we should get an exception which is caught by the 'try'
    // block and no request is made.
    try {
      var url = FN.config.urls.licensingBase + 'trial_trackings';
      var tracker = ga.getAll()[0];
      var clientId = tracker.get('clientId');
      var data = { ga_client_id: clientId };
      var query = window.location.search;
      var validCampaignParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_id',
        'utm_mt',
        'utm_device',
        'lpurl',
        'gclid',
        'fbclid',
        'msclkid'
      ];
      var campaignParams, campaignParam;
      var i;

      // Extract campaign params from query string into [key, value] arrays
      campaignParams = query.slice(1).split('&').map((pair) => pair.split('='));

      for (i = 0; i < campaignParams.length; i++) {
        var campaignParam = campaignParams[i];
        if (validCampaignParams.indexOf(campaignParam[0]) > -1) {
          data[campaignParam[0]] = campaignParam[1];
        }
      }

      data = JSON.stringify(data);

      $.ajax(url, {
        contentType: 'application/json',
        data: data,
        type: 'POST'
      });
    }
    catch { }
  },


  startFileDownload: function (event) {
    var downloadUrl = $(event.target).attr('href');
    var downloadIframe = '<iframe src="' + downloadUrl + '" width="0" height="0" style="display:none;"></iframe>';
    $('body').append(downloadIframe);
  },


  showDownloadOverlay: function () {
    var baseElementSelector = '.' + FN.startTrial.selectors.downloadOverlayBaseClass;
    FN.overlay.init({ baseElement: baseElementSelector });
  },


  showDownloadAnimation: function () {
    var downloadAnimationContainer = $('.' + FN.startTrial.selectors.downloadOverlayBaseClass).find(FN.startTrial.selectors.downloadAnimationSelector);
    var animationImage = '<img src="' + FN.config.urls.base + FN.startTrial.constants.downloadImage + '">';
    $(downloadAnimationContainer).html(animationImage);
  },


  signupComplete: function (context) {
    if (context.responseData.status >= 400) return;

    FN.overlay.showContent(1, false);
  }
};
